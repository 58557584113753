import LogoFishial from 'assets/images/_logos/logoFishial.png';
import BgEnergy from 'assets/images/Industries/bgEnergy.png';
import BgFishial from 'assets/images/Industries/bgFishial.png';
import BgHotels from 'assets/images/Industries/bgHotels.png';
import BgMOBD from 'assets/images/Industries/bgMOBD.png';
import BgNUUS from 'assets/images/Industries/bgNUUS.png';
import BgSpoton from 'assets/images/Industries/bgSpoton.png';
import BgTickPredict from 'assets/images/Industries/bgTickPredict.png';
import BgTulerie from 'assets/images/Industries/bgTulerie.png';
import BgUdacity from 'assets/images/Industries/bgUdacity.png';
import BgVoice from 'assets/images/Industries/bgVoice.png';
import LogoEnergy from 'assets/images/Industries/logoEnergy.png';
import LogoHotels from 'assets/images/Industries/logoHotels.png';
import LogoMOBD from 'assets/images/Industries/logoMOBD.png';
import LogoNUUS from 'assets/images/Industries/logoNUUS.png';
import LogoSpoton from 'assets/images/Industries/logoSpoton.png';
import LogoTickPredict from 'assets/images/Industries/logoTickPredict.png';
import LogoTulerie from 'assets/images/Industries/logoTulerie.png';
import LogoUdacity from 'assets/images/Industries/logoUdacity.png';
import LogoVoice from 'assets/images/Industries/logoVoice.png';
import {
    ROUTE_CASE_STUDIES_FISHIAL,
    ROUTE_CASE_STUDIES_HOTELS,
    ROUTE_CASE_STUDIES_MOBD,
    ROUTE_CASE_STUDIES_NUUS,
    ROUTE_CASE_STUDIES_RESPECT,
    ROUTE_CASE_STUDIES_SPOTON,
    ROUTE_CASE_STUDIES_TICK_PREDICT,
    ROUTE_CASE_STUDIES_TULERIE,
    ROUTE_CASE_STUDIES_UDACITY,
    ROUTE_CASE_STUDIES_VOICE,
} from 'shared/paths';

export const INDUSTRIES_RETAIL = '#retail';
export const INDUSTRIES_COMMUNITY = '#community';
export const INDUSTRIES_FINANCIAL = '#financial';
export const INDUSTRIES_ENTERTAINMENT = '#entertainment';
export const INDUSTRIES_HEALTHCARE = '#healthcare';
export const INDUSTRIES_AUTOMOTIVE = '#automotive';
export const INDUSTRIES_SPORTS = '#sports';
export const INDUSTRIES_ENERGY = '#energy';
export const INDUSTRIES_IT = '#it';
export const INDUSTRIES_HOSPITALITY = '#hospitality';

export type TIndustry =
    | typeof INDUSTRIES_RETAIL
    | typeof INDUSTRIES_COMMUNITY
    | typeof INDUSTRIES_FINANCIAL
    | typeof INDUSTRIES_ENTERTAINMENT
    | typeof INDUSTRIES_HEALTHCARE
    | typeof INDUSTRIES_AUTOMOTIVE
    | typeof INDUSTRIES_SPORTS
    | typeof INDUSTRIES_ENERGY
    | typeof INDUSTRIES_ENERGY
    | typeof INDUSTRIES_IT
    | typeof INDUSTRIES_HOSPITALITY;

export const INDUSTRIES_MENU_ITEMS = [
    {
        name: 'Retail',
        link: INDUSTRIES_RETAIL,
    },
    {
        name: 'Community',
        link: INDUSTRIES_COMMUNITY,
    },
    {
        name: 'Financial',
        link: INDUSTRIES_FINANCIAL,
    },
    {
        name: 'Entertainment',
        link: INDUSTRIES_ENTERTAINMENT,
    },
    {
        name: 'Healthcare',
        link: INDUSTRIES_HEALTHCARE,
    },
    {
        name: 'Automotive',
        link: INDUSTRIES_AUTOMOTIVE,
    },
    {
        name: 'Sports',
        link: INDUSTRIES_SPORTS,
    },
    {
        name: 'Energy',
        link: INDUSTRIES_ENERGY,
    },
    {
        name: 'Information Technology',
        link: INDUSTRIES_IT,
    },
    {
        name: 'Hospitality',
        link: INDUSTRIES_HOSPITALITY,
    },
];

export const INDUSTRIES_CONTENTS = {
    [INDUSTRIES_RETAIL]: {
        stats: {
            numbers: ['50 000', '30 000', '$118 M'],
            descriptions: ['End Devices', 'b2b customers', 'global revenue'],
        },
        logo: LogoSpoton,
        bg: BgSpoton,
        header: 'Mobile payment technology and a management system for restaurants and small businesses.',
        hashtags: [
            'integrations with 3rd party',
            'payment gateways',
            'payments',
            'hardware',
            'admin panel',
        ],
        description:
            'From idea to execution. Client approached us with an idea for POS. Needed to develop a custom, cloud-based Point-of-Sale solution for modern times with user-friendly interface that gives a venue complete view of their business and revenue streams. [...]',
        readMoreLink: ROUTE_CASE_STUDIES_SPOTON,
    },
    [INDUSTRIES_COMMUNITY]: {
        stats: {
            numbers: ['30 000', '1 M+', '900 k+'],
            descriptions: [
                'recognized fish species',
                'images uploaded',
                'fish detected',
            ],
        },
        logo: LogoFishial,
        bg: BgFishial,
        header: 'Fish recognition and classification platform',
        hashtags: ['ai', 'vision processing', 'admin panels', 'software'],
        description:
            'Lack of globally available database with fish species. The idea was to build largest open-source fish species image library labelled for AI machine learning and seek the development of a highly accurate ope-source AI model that can identify fish species world wide. [...]',
        readMoreLink: ROUTE_CASE_STUDIES_FISHIAL,
    },
    [INDUSTRIES_FINANCIAL]: {
        stats: {
            numbers: ['$1M', '60 000', '7'],
            descriptions: ['MRR', 'customers in 1st 2y', 'markets connected'],
        },
        logo: LogoTickPredict,
        bg: BgTickPredict,
        header: 'Trading platform for trading veterans',
        hashtags: ['integrations with 3rd party', 'software'],
        description:
            'Client wanted to enter crypto-trading market by providing possibility to trade from one place on different market places, dedicated for institutional use. [...]',
        readMoreLink: ROUTE_CASE_STUDIES_TICK_PREDICT,
    },
    [INDUSTRIES_ENTERTAINMENT]: {
        stats: {
            numbers: ['200 000', '548', '$85 000'],
            descriptions: [
                'items lent',
                'number of luxury brands',
                'most expensive item',
            ],
        },
        logo: LogoTulerie,
        bg: BgTulerie,
        header: 'Peer-peer clothing rental community',
        hashtags: ['software', 'fashion', 'entertainment'],
        description:
            'Tulerie is a peer-to-peer luxury rental app with shopping, sustainability, and sharing on the mind. The Tulerie community is made up of like-minded members who are obsessed with fashion and are eager to expand their wardrobes in a way that is sustainable for both the environment and their wallets. [...]',
        readMoreLink: ROUTE_CASE_STUDIES_TULERIE,
    },
    [INDUSTRIES_HEALTHCARE]: {
        stats: {
            numbers: ['120 h', '11', '36 000'],
            descriptions: [
                'transcripted medical interview so far',
                'healthcare institutions involved',
                'number of interviews',
            ],
        },
        logo: LogoVoice,
        bg: BgVoice,
        header: 'Automated AI-based medical interview',
        hashtags: ['software', 'hardware'],
        description:
            'The scope of the project is to carry out interdisciplinary research, development and pre-implementation work in the ICT sector to create a globally innovative technological solution. It will be an intelligent speech processing system designed for the medical industry. [...]',
        readMoreLink: ROUTE_CASE_STUDIES_VOICE,
    },
    [INDUSTRIES_AUTOMOTIVE]: {
        stats: {
            numbers: ['150 h', '3 TB', 'CE, RoHs'],
            descriptions: [
                'devices produced',
                'data recorded',
                'certification',
            ],
        },
        logo: LogoMOBD,
        bg: BgMOBD,
        header: 'Fleet management system',
        hashtags: ['embedded-software', 'hardware'],
        description:
            'Fractured market providers without complex solution that would bring the latest hardware devices along with intuitive and useful online data administration panel along with driver-side communication capability. [...]',
        readMoreLink: ROUTE_CASE_STUDIES_MOBD,
    },
    [INDUSTRIES_SPORTS]: {
        stats: {
            numbers: ['4', '10 000', '20 000+'],
            descriptions: [
                'times league championship',
                'stadium seats that used our software',
                'users',
            ],
        },
        logo: LogoNUUS,
        bg: BgNUUS,
        header: 'Nuus - sports managing system',
        hashtags: ['software', 'hardware'],
        description:
            'Nuus is Saas product, browser-based administration panel for managing sports club multimedia content with additional functionalities - end-customers mobile applications, AI module, online event ticketing system or even stadium banner display module.',
        readMoreLink: ROUTE_CASE_STUDIES_NUUS,
    },
    [INDUSTRIES_ENERGY]: {
        stats: {
            numbers: ['15 000', 'Nasdaq', '23'],
            descriptions: [
                'customers across EU',
                'EU commodities traded company',
                'countries market presence',
            ],
        },
        logo: LogoEnergy,
        bg: BgEnergy,
        header: 'Green energy hardware control devices',
        hashtags: ['software', 'hardware'],
        description:
            'Respect Energy brings together independent power producers, accredited and institutional investors holding assets in renewables or undertaking investments in wind and solar green energy power plants. [...]',
        readMoreLink: ROUTE_CASE_STUDIES_RESPECT,
    },
    [INDUSTRIES_IT]: {
        stats: {
            numbers: ['1.6 M', '14', '$100 M'],
            descriptions: [
                'users worldwide',
                'years on the market',
                'global revenue',
            ],
        },
        logo: LogoUdacity,
        bg: BgUdacity,
        header: 'Enterprise schooling educational system',
        hashtags: ['software', 'information technology'],
        description:
            'Udacity is an American educational organization founded in 2011, offering massive open online courses for students as well as teams and enterprise. [...]',
        readMoreLink: ROUTE_CASE_STUDIES_UDACITY,
    },
    [INDUSTRIES_HOSPITALITY]: {
        stats: {
            numbers: ['602', '4', '2996'],
            descriptions: [
                'rooms available',
                'EU locations',
                'minutes saved on check-in',
            ],
        },
        logo: LogoHotels,
        bg: BgHotels,
        header: 'Hotel reservation system',
        hashtags: ['software', 'hardware'],
        description:
            'NDA is one of the pioneers in a new category on the European market offering private purpose-built student accommodation (PBSA). This business step is in line with a substantial shortfall of student housing in Europe. [...]',
        readMoreLink: ROUTE_CASE_STUDIES_HOTELS,
    },
};
