import { Section } from 'components/_layout/Section/Section';
import * as Common from 'components/_universal/Common';
import { navigate } from 'gatsby';
import { useTheme } from 'layout/ThemeContext';
import React, { useEffect, useState } from 'react';
import { TagTile } from 'sections/Blog/BlogItem/BlogItem.styled';
import {
    DescriptionText,
    IndustryContent,
    IndustryDescription,
    IndustryLogo,
    MenuAbsoluteContainer,
    MenuItem,
    NoWrapSection,
    ReadMoreButton,
    SideMenu,
    StatsWithBg,
} from 'sections/Industries/Industries.styled';
import { MenuSwiper } from 'sections/Industries/MenuSwiper';

import {
    INDUSTRIES_CONTENTS,
    INDUSTRIES_MENU_ITEMS,
    TIndustry,
} from 'shared/data/Industries.data';
import { ROUTE_CASE_STUDIES } from 'shared/paths';
import shortId from 'shortid';
import { B0, B2, H2 } from 'styles/Typography.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';

export const Industries = () => {
    const [tab, setTab] = useState<TIndustry>('#retail');
    const isSm = useBreakpoint('sm');
    const { setCaseStudiesTagsSelected } = useTheme();

    const { stats, logo, bg, header, hashtags, description, readMoreLink } =
        INDUSTRIES_CONTENTS[tab] ?? {
            stats: {
                numbers: [],
                descriptions: [],
            },
            bg: '',
            logo: '',
            header: '',
            hashtags: [],
            description: '',
            readMoreLink: '',
        };

    const browserLocation =
        typeof window !== 'undefined' && window.location.href;

    // change tab when path changes (from footer)
    useEffect(() => {
        const hash = window.location.hash as TIndustry;
        if (hash) {
            setTab(hash ?? '#retail');

            if (window.scrollY > 360) {
                scrollTo({
                    behavior: 'smooth',
                    top: 300,
                });
            }
        }
    }, [browserLocation]);

    const handleMenuItemClick = (tabName: string) => {
        window.location.hash = tabName;
    };

    const handleTagClick = (e: Event, tag: string) => {
        e.stopPropagation();
        setCaseStudiesTagsSelected([tag]);
        navigate(ROUTE_CASE_STUDIES);
    };

    return (
        <Common.Div>
            <Section
                title="industries"
                withBreadCrumbs
                variant="secondary"
                isFirst
            />
            <MenuAbsoluteContainer>
                <MenuSwiper tab={tab} isVisible={isSm} />
                {!isSm && (
                    <SideMenu>
                        {INDUSTRIES_MENU_ITEMS.map(({ name, link }) => (
                            <MenuItem
                                isActive={tab === link}
                                key={shortId.generate()}
                                onClick={() => handleMenuItemClick(link)}
                            >
                                {name}
                            </MenuItem>
                        ))}
                    </SideMenu>
                )}
                <IndustryContent>
                    <StatsWithBg bg={bg} position="relative" w="100%">
                        <NoWrapSection>
                            <H2>{stats.numbers[0]}</H2>
                            <B2>{stats.descriptions[0]}</B2>
                        </NoWrapSection>
                        <NoWrapSection>
                            <H2>{stats.numbers[1]}</H2>
                            <B2>{stats.descriptions[1]}</B2>
                        </NoWrapSection>
                        <NoWrapSection>
                            <H2>{stats.numbers[2]}</H2>
                            <B2>{stats.descriptions[2]}</B2>
                        </NoWrapSection>
                    </StatsWithBg>
                    <IndustryDescription>
                        <Common.Div>
                            <IndustryLogo src={logo} mb={2} />
                            <B0 mb={1}>{header}</B0>
                            <Common.Div
                                flex="row"
                                gap="8px"
                                mb={5}
                                flexWrap="wrap"
                            >
                                {hashtags.map((h: string) => (
                                    <TagTile
                                        onClick={(e) => handleTagClick(e, h)}
                                    >
                                        #{h}
                                    </TagTile>
                                ))}
                            </Common.Div>
                            <DescriptionText mb={6} mt={2}>
                                {description}
                            </DescriptionText>
                        </Common.Div>
                        <ReadMoreButton
                            text="read more"
                            onClick={() => navigate(readMoreLink)}
                        />
                    </IndustryDescription>
                </IndustryContent>
            </MenuAbsoluteContainer>
        </Common.Div>
    );
};
